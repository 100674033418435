.m-heatmap {
    margin-top: 40px;

    &__resultsView {
        display: flex;
        align-items: end;
        justify-content: space-between;
        flex-direction: column;

        @include media-up(m) {
            flex-direction: row;
        }
    }

    &__results {
        margin-right: 0px;
        width: 100%;

        @include media-up(m) {
            width: 70%;
            margin-right: 30px;
        }
    }

    &__textInfo {
        text-align: center;
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;
        padding:10px;
        background-color: $white;
        border:1px solid $gray-11;
        margin-top:30px;
    }

    &__resultsDisplaying {
        font-size: 24px;
        letter-spacing: -0.3px;
        line-height: 24px;
        font-weight: 600;
    }

    &__resultsShowing {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.17px;
        line-height: 24px;
    }

    &__view {
        display: flex;
        align-items: center;
        margin-top: 30px;

        @include media-up(m) {
            margin-top: 80px;
        }

        .m-selectGroup {
            margin-top: 0;
        }
    }

    &__viewChange {
        font-size: 13px;
        letter-spacing: -0.2px;
        line-height: 16px;
        margin-right: 10px;
        white-space: nowrap;
    }

    &__tableDiv {
        overflow-x: auto;
        overflow-y: hidden;
        padding-left: 1px;
        @include scrollbar;
    }

    &__tableHeader {
        height: 60px;
    }

    &__table {
        width: 100%;
        margin-top: 20px;

        th {
            border: 0;
            // transform: rotate(-30deg);
            white-space: nowrap;
            max-width: 140px;
            min-width: 140px;
            // text-align: left;
            text-transform: capitalize;
            padding: 0px;
            font-weight: 600;
            font-size: 14px;
            cursor: unset;
            
            &:before {
                content: '';
                display: inline-block;
            }

            &.-company {
                position: sticky;
                top: 0;
                left: 0;
                z-index: 20;

                &:before {
                    content: '';
                    display: inline-block;
                    height: 175px;
                    width: 100%;
                    background-color: $main-color;
                    position: absolute;
                    // bottom: -5px;
                    // left: 25px;
                    bottom: 0px;
                    left: -2px;
                    // transform: rotate(30deg);
                }

                svg {
                    position: absolute;
                    left: 5px;
                    cursor: pointer;
                }
            }

            .sortIcon {
                position: relative;
                bottom: -10px;
                // left: 120px;
                display:flex;
                justify-content: center;
                align-items: center;
                
            }
            .sortIconHeatMap {
                bottom: 5px;
                display:flex;
                justify-content:center;
                align-items:center;
                // padding-bottom:30px;
                height:auto;
                min-width:100px;
                position:relative;
                width:auto;
                span{
                    display:inline-block;
                    margin:0 5px;
                }
            }
        }

        tbody {
            background-color: $white;
            position: relative;

            &.-blur {
                tr {
                    filter: blur(3px);
                }
            }
        }

        tr {
            background-color: transparent;
        }

        td {
            &:first-child {
                padding: 13px;
                font-size: 13px;
                letter-spacing: -0.2px;
                line-height: 20px;
                text-align: left;
                // white-space: nowrap;
                max-width: max-content;
            }

            border: 1px solid $gray-11;

            &.-red {
                &::after {
                    border: 1px solid rgba(228, 36, 52, 0.1);
                    background-color: rgba(228, 36, 52, 0.1);
                }
            }

            &.-green {
                &::after {
                    border: 1px solid rgba(6, 190, 74, 0.3);
                    background-color: rgba(6, 190, 74, 0.2);
                }
            }

            &.-green,
            &.-red {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: calc(100% - 10px);
                    height: calc(100% - 10px);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 3px;
                }
            }

            &.-company {
                // max-width: 200px;
                position: sticky;
                left: 0;
                // white-space: break-spaces;
                background-color: white;
                z-index: 5;

                span {
                    font-weight: bold;
                    margin-left: 3px;
                    font-size: 12px;
                }
                
                &::before {
                    content: '';
                    background-color: transparent;
                    position: absolute;
                    width: calc(100% + 2px);
                    height: 100%;
                    top: 0;
                    left: -2px;
                    border-right: 2px solid #d9dfe4;
                    border-left: 2px solid #d9dfe4;
                }
            }
        }

    }

    &__thContent {
        display: flex;
        align-items: center;
        // margin: 0 auto;
        // width: max-content;

        img, .image {
            min-width: 34px;
            min-height: 34px;
            width: 34px;
            height: 34px;
            border-radius: 3px;
        }

        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(223, 223, 223, 0.5);
        }
    }

    &__namePartner {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        // text-align: left;
        margin-left: 10px;

        span {
            color: $blue-4;
            font-size: 12px;
            letter-spacing: -0.15px;
            line-height: 15px;

            &.-partner {
                opacity: 0.5;

                font-weight: 400;
            }
        }

    }

    &__dealProcessContainer {
        display: flex;
        flex-direction: column;
        background-color: $white;
        border: 1px solid #D1D1D9;
        padding: 10px;

        .m-message {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            color: #383F52;
            font-size: 12px;
            letter-spacing: -0.15px;
            line-height: 14px;
            margin: 3px 0;

            &__buttons {
                margin: 0 0 5px -5px; 
            }

            .all {
                margin: 0 5px;
                text-decoration: underline;
                cursor: pointer;
    
                &:hover {
                    color: $secondary-color;
                }
            }
    
        }

        
    }

    &__dealProcessItems {
        display: flex;
        flex-wrap: wrap;
        
        &.-bottom {
            padding-bottom: 35px;
        }

        .item {
            display: flex;
            align-items: center;
            width: max-content;
            margin-right: 10px;
            margin-bottom: 13px;
            border: 1px solid #CCCED6;
            border-radius: 2px;
            background-color: #F4F2FA;
            padding: 5px 10px 5px 5px;
            cursor: pointer;
            transition: color .3s, background-color .3s;

            &.-active {
                border: 1px solid $primary-color;
                background-color: #F7DDDD;
                color: $black;

                span {
                    &.-companyName {
                        color: $black;
                    }
                }

                .image {
                    background-color: $white;
                }

                svg {
                    stroke: $primary-color;
                }
            }

            span {
                &.-companyName {
                    color: #B7B9C0;
                }
            }

            img, .image {
                min-width: 20px;
                min-height: 20px;
                width: 20px;
                height: 20px;
                border-radius: 3px;
            }

            .image {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #EAE9ED;
            }
           
        }
    }

    &__droppableContainer {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        overflow-x: auto;
    }

    &__droppableRow {
        display: flex;
        background-color: $white;
        min-width: fit-content;

        &.-header, &.-message {
            background-color: transparent;

            .m-heatmap__droppableCell {
                // border: none;
                border: 0.5px solid transparent;
                display: flex;
                justify-content: center;
                color: $black;
            }
        }

        // &.-active {
        //     border: 1px dashed #018fd0;
        // }

        &.-space {
            padding: 20px;
        }

        .message {
            color: #383F52;
            font-size: 12px;
            letter-spacing: -0.15px;
            line-height: 14px;
            margin: 3px 0;
        }
    }

    &__droppableCell {
        flex: 1;
        border: 1px solid $gray-11;
        white-space: nowrap;
        padding: 5px;
        color: transparent;

        &.-company {
            padding: 13px;
            font-size: 13px;
            letter-spacing: -0.2px;
            line-height: 20px;
            text-align: left;
            min-width: 200px;
            max-width: 200px;
            white-space: normal;
        }

        &.-red {
            &::after {
                border: 1px solid rgba(228, 36, 52, 0.1);
                background-color: rgba(228, 36, 52, 0.1);
            }
        }

        &.-green {
            &::after {
                border: 1px solid rgba(6, 190, 74, 0.3);
                background-color: rgba(6, 190, 74, 0.2);
            }
        }

        &.-green,
        &.-red {
            position: relative;

            &::after {
                content: '';
                display: block;
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 3px;
            }
        }
    }

    &__droppableArea {
        border: 1px dashed #a7a2af;
        padding: 20px;
    }

    &__droppableAreaActive {
        border: 1px dashed #a7a2af;
    }

    .m-selectGroup {
        z-index: 21;
    }

}
.m-isInterested {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.15px;
    transition: color .3s;
    object-fit: cover;
    width: 100%;

    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 100%;

        &.-open {
            // color: $blue-1;
        }
    }

    &__options {
        background-color: $white;
        border: 1px solid $gray-11;
        box-shadow: 0 5px 20px -11px rgba(0, 18, 52, 0.35);
        border-radius: 5px;
        position: absolute;
        right: 10px;
        top: -100px;
        z-index: 2;
        padding: 15px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        pointer-events: none;

        animation-name: fade-down;
        animation-duration: .3s;

        @keyframes fade-down {
            from {
                opacity: 0;
                transform: translateY(-30px);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }

    .a-chevron {
        margin-left: 6px;

        &.-up {
            border-bottom-color: $blue-1;
            border-right-color: $blue-1;
            margin-top: 4px;
        }
    }

    &__list {
        background-color: $white;
        border: 1px solid $gray-11;
        box-shadow: 0 5px 20px -11px rgba(0, 18, 52, 0.35);
        border-radius: 5px;
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 2;
        padding: 15px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        animation-name: fade-down;
        animation-duration: .3s;

        @keyframes fade-down {
            from {
                opacity: 0;
                transform: translateY(-30px);
            }

            to {
                opacity: 1;
                transform: translateY(0);
            }
        }

        .dealStage {
            padding: 5px;
            cursor: pointer;
            width: 100%;

            &.-active {
                font-weight: 600;
            }

            &:hover, &.-active {
                &.-item9 {
                    color: $purple-1;
                }
        
                &.-item10 {
                    color: $blue-6;
                }
        
                &.-item11 {
                    color: $orange-1;
                }
        
                &.-item12 {
                    color: $brown-1;
                }
        
                &.-item13 {
                    color: $green-2;
                }
        
                &.-item14 {
                    color: $green-3;
                }
        
                &.-item15 {
                    color: $red-2;
                }
        
                &.-item16 {
                    color: #282a2f;
                }

        
            }
        }
    }
}
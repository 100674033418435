/* The side navigation menu */
.m-sidenav {
  // height: 100%;
  height: calc(100vh - 120px);
  /* 100% Full-height */
  width: $sidenav-width;
  position: fixed;
  /* Stay in place */
  z-index: 25;
  /* Stay on top */
  top: 135px;
  /* Stay at the top */
  left: 0;
  background-color: $white;
  // overflow-x: hidden;
  /* Disable horizontal scroll */
  // padding-top: 20px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
  border: 1px solid $gray-10;
  border-top: 0;
  border-left: 0;

  @include media-up(l) {
    top: 70px;
    height: calc(100vh - 70px);
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background-color: #ececec;
    border-radius: 4px;
    opacity: 0.5;
  }

  ::-webkit-scrollbar-thumb {
    background: $secondary-color;
    border-radius: 4px;
  }

  // Sidebar open
  &.-closed {
    width: 0px;

    @include media-up(m) {
      width: 60px;
    }

    .m-sidenav {

      &__itemLabel {
        opacity: 0;
        width: 0;
        color: $white;
      }

      &__item {
        &:hover {
          width: max-content;
          border-radius: 0 5px 5px 0;
          color: $white;
          z-index: 999;

          svg {
            stroke: $white;

          }

          .m-sidenav__itemLabel {
            overflow: visible;
            opacity: 1;
            width: 100%;
            height: 40px;
            line-height: 40px;
            padding: 0 20px 0 10px;
          }
        }
      }

      &__toggler {
        background-color: $secondary-color;
        border-color: $secondary-color;

        @include media-down(m) {
          display: none;
        }

        &::after {
          left: calc(50% - 2px);
          transform: translate(-50%, -50%) rotate(-135deg);
          border-color: $white;
        }
      }
    }
  }

  &__scroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 22;
  }

  &__item {
    position: relative;
    height: 40px;
    outline: 0;
    width: 64px;
    transition: width .5s;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 0;
      background-color: $primary-color;
      transition: width .5s;
    }



    &.-active {
      background-color: rgba($primary-color, .15);

      &::before {
        width: 4px;
      }

      svg {
        stroke: $primary-color;
      }
    }

    &:hover {
      background-color: $primary-color;

      .m-sidenav__itemLabel {
        color: $white;
      }

      svg {
        stroke: $white;

      }
    }

    svg {
      min-width: 24px;
      transition: stroke .3s;
    }


  }

  &__itemLabel {
    width: 100%;
    margin-left: 5px;
    padding: 0 0 0 20px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    letter-spacing: -.2px;
    color: $blue-4;
    opacity: 1;
    transition: opacity .5s, padding-right .3s, width .5s, color .5s;
  }

  &__toggler {
    position: absolute;
    right: -17px;
    top: 25px;
    height: 30px;
    width: 30px;
    border: 1px solid $gray-10;
    border-radius: 15px;
    background-color: $white;
    z-index: 2;
    transition: background-color .5s, border-color .5s;
    cursor: pointer;

    @include media-up(m) {
      display: block;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%) rotate(45deg);
      width: 7px;
      height: 7px;
      border: 1.5px solid $blue-4;
      border-top: 0;
      border-right: 0;
      transition: transform .5s, left .5s, border-color .5s;
    }
  }

  &__item,
  &__group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 5px 0 5px 20px;
    color: $color-primary;
    transition: 0.5s;

    &__label {
      text-decoration: none;
      font-size: 14px;
      line-height: 40px;
      letter-spacing: -.2;
    }
  }


  &__item {
    width: 100%;
    transition: background-color .5s, color .5s;
  }

  &__separator {
    background: $gray-10;
    min-width: 80%;
    height: 1px;
    margin: 5px 10% 5px;
  }
}
.m-reviewStars {

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        .a-btn {
            margin-top: 10px;
            min-width: 85px;
        }
    }

    &__ranking {
        display: flex;
        align-items: center;
        font-size: 14px;
        background-color: white;
        padding: 10px 10px 5px;
        border-radius: 5px;
        border: 1px solid #d1d1d9;

        .title {
            margin-bottom: 8px;
            margin-right: 10px;
        }
    }

    &__box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        // margin-top: 20px;

        svg {
            stroke: none;
            fill: rgba(192, 192, 192, 0.5);
            cursor: pointer;
            transition: fill .1s;
        }
    }

    &__table {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        svg {
            stroke: none;
            fill: rgba(192, 192, 192, 0.5);
        }
    }

    &__active {
        svg {
            fill: rgb(255, 230, 0);
        }
    }

    &__box > span:hover,
    &__box > span:hover ~ span {
        svg {
            transition: fill .1s;
            fill: rgb(255, 230, 0);
        }
    }
}
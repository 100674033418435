.m-generalTerms {
    padding: 50px 45px 150px;

    &__goBack {
        position: fixed;
        top:20px;
        right:30px;
        width: 38px;
        height: 38px;
        background-color: $red-4;
        color: $white;
        border-radius: 50%;
        transform: rotate(45deg);
        cursor: pointer;
        transition: transform .3s;

        &:hover {
            transform: rotate(135deg);
        }

        &::after, &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24px;
            height: 2px;
            background-color: $white;
            transform: translate(-50%,-50%);
        }
        &::after {
            transform: translate(-50%,-50%) rotate(90deg);

        }
        
    }

    @include media-up(m) {
        padding: 50px 150px 150px;
    }

    @include media-up(l) {
        padding: 50px 250px 150px;
    }

    h4 {
        display: block;
        margin: 15px auto;
        text-align: center;
        font-weight: bold;

        @include media-up(l) {
            max-width: 75%;
        }
    }

    img {
        display: block;
        margin: auto;
        max-width: 160px;

        @include media-up(l) {
            max-width: 250px;
        }
    }

    &__parts {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
        span {
            font-size: 18px;
            font-weight: bold;
        }

        h3 {
            margin-top: 20px;
            font-weight: 500;
        }

        &--list {
            margin-top: 15px;
            span {
                margin-left: 15px;
                margin-top: 15px;
            }
        }

        &--flex {
            display: flex;
            flex-wrap: wrap;
        }

        &--part {
            max-width: 50%;
            margin-right: 25px;
            margin-top: 20px;

            @include media-up(xl) {
                max-width: 30%;
            }
        }

    }

    &__table {
        margin-top: 10px;
        text-align: center;

        span {
            font-size: 22px;
            font-weight: bold;
        }
        table {
            margin: 25px auto;
            width: 100%;
            max-width: 800px;
            table-layout: fixed;
            thead {
                tr {
                    color: $white;
                    
                    th {
                        background-color: rgba($red-4, $alpha: 1);
                        pointer-events: none;

                        &.-blank {
                            background-color: transparent;
                        }
                    }
                }
            }

            tbody {
                td {
                    max-width: 250px;
                    padding: 10px;
                    background-color: $white;
                    border-bottom: 1px solid $gray-2;

                    &:first-of-type {
                        text-align: left;
                    }
                }

                tr {
                    &:first-child {
                        td {
                            padding-top: 30px;
                        }
                    }
                    &.-main {
                        font-weight: bold;
                        &:not(:first-child) {

                        td {
                            border-top: 1px solid $red-4;
                        }
                    }
                }
                }
            }
        }
    }
}